import React, { useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const { user, logoutUser } = useContext(AuthContext);
  const inputRef = useRef(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user ? (
        [
          <MenuItem key="my-account" onClick={() => { handleMenuClose(); navigate(`/profile/${user.username}`); }}>Settings</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>,
          <MenuItem key="logout" onClick={() => { logoutUser(); navigate('/login'); }}>Logout</MenuItem>
        ]
      ) : (
        [
          <MenuItem key="login" onClick={() => { handleMenuClose(); navigate('/login'); }}>Login</MenuItem>,
          <MenuItem key="register" onClick={() => { handleMenuClose(); navigate('/register'); }}>Register</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>
        ]
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>


          {/* Logo */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ display: { xs: 'block', sm: 'block' }, fontWeight: 'bold' }}
          >
            UDots
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* Search and Profile Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchIcon
              onClick={() => { navigate('/search'); }}
              sx={{ cursor: 'pointer', marginRight: 2 }}
            />

            {user ? (
               <>
<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
  <AddIcon 
    onClick={() => { navigate('/app/createlink'); }} 
    sx={{ cursor: 'pointer' }} 
  />
  <IconButton
    size="large"
    edge="end"
    aria-label="account of current user"
    aria-controls={menuId}
    aria-haspopup="true"
    onClick={() => { navigate(`/${user.username}`); }}
    color="inherit"
  >
    <AccountCircle />
  </IconButton>
  <MoreVertIcon 
    onClick={handleProfileMenuOpen} 
    aria-controls={menuId} 
    aria-haspopup="true" 
    sx={{ cursor: 'pointer' }} 
  />
</Box>
             </>
            ) : (
              <>
                
                <MenuItem onClick={() => { navigate('/login'); }}>Login</MenuItem>
                <MenuItem onClick={() => { navigate('/register'); }}>Register</MenuItem>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
